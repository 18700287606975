.MuiTablePagination-displayedRows {
    font-size: 1em !important;
  }

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 8px !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color:#009ee3 !important;
  border-radius: 5px !important;
}