/* Split the screen in half */
.split1 {
    height: 100%;
    width: 70%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  }

.split2 {
    height: 100%;
    width: 30%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  /* Control the left side */
  .left {
    left: 0;
    background-color: #019EE3;
  }
  
  /* Control the right side */
  .right {
    right: 0;
    background-color: red;
  }
  
  /* If you want the content centered horizontally and vertically */
  .leftCentered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .rightCentered {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(0%, -50%);
    text-align: center;
  }
  
  /* Style the image inside the centered container, if needed */
  .centered img {
    width: 150px;
    border-radius: 50%;
  }

  .securityIcon{
    transform: scale(17.8)
  }

  .leftText{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .leftBackground{
    position: absolute;
    background-image: url("https://www.wackenhut.de/fileadmin/_content/Unternehmen/Standorte/Au%C3%9Fenaufnahme-Mercedes-Benz-Wackenhut-Nagold-bei-Nacht-Panorama-1600x500.jpg?1658931938273");
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    z-index: 0;
    }

    .divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}

.test{
    z-index: 1;
}


  /* .rightText{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  } */

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}